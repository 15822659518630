<script>
    import { required } from "vuelidate/lib/validators";
    import validationMessages from '@/components/validations'
    import Swal from "sweetalert2";
    import Notification from "@/services/Notification";
    import Content from '@/services/Content';
    import flatPickr from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';
    import 'flatpickr/dist/plugins/confirmDate/confirmDate.css';
    import moment from "moment";
 
    export default {
        components:{
            flatPickr,
            validationMessages
        },
        validations: {
            notifData:{
                message:{
                    required
                }
            },
        },
        data() {
            return {
                notification_languages: JSON.parse(process.env.VUE_APP_NOTIFICATION_LANG),
                csrf_token: localStorage.getItem('csrf_token'),
                showModal: false,
                showSchedule: false,
                tryingToSubmit: false,
                submitted: false,
                notifTypes: false,
                datetimeconfig: {
                    enableTime: true,
                    time_24hr: true,
                    dateFormat: "Y-m-d H:i",
                    minDate: "today",
                    minuteIncrement: 1,
                    defaultDate: new Date(),
                    plugins: [new ConfirmDatePlugin({})]
                },
                notifData: {
                    type: '',
                    message: '',
                    title: '',
                    recipient: 'ALL',
                    schedule_time: '',
                    language: ''
                },
                deepLinkingAction:'',
                deepLinkingActionNews:'',
                showDeepLinkingActionNews: false,
                deepLinkingActionVideos:'',
                showDeepLinkingActionVideos: false,
                deepLinkingActionMDC:'',
                showDeepLinkingActionMDC: false,
                deepLinkingFullScreen:'Yes',
                deep_link: [],
                deepLinkActions:[
                    { "action": "news", "name": "News" },
                    { "action": "matchcenter", "name": "Match Center" },
                    { "action": "mdc", "name": "Match Day Challenge" },
                    { "action": "videos", "name": "Videos" },
                    { "action": "tickets", "name": "Tickets" },
                ],
                deepLinkActionsNews:[],
                deepLinkActionsVideos:[],
                deepLinkActionsMDC:[],
                deepLinkFullScreen:[
                    { "key": true, "value": "Yes" },
//                    { "key": false, "value": "No" },
                ],
            };
        },
        created() {
            this.getNotificationTypes()
        },
        methods: {
            refreshNotificatinData() { 
                this.$emit('refreshScreen') //event from parent
            },
            addNotification(){
                //submit
                this.tryingToSubmit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToSubmit = false;
                    return;
                } else {
                    let formData = new FormData();
                    formData.append('type', this.notifData.type);
                    formData.append('message', this.notifData.message);
                    formData.append('title', this.notifData.title);
                    formData.append('recipient', this.notifData.recipient);
                    formData.append('schedule_time', this.notifData.schedule_time);
                    formData.append('language', this.notifData.language);
                    formData.append('csrf_token', this.csrf_token);
                    formData.append('deep_link[action]', this.deepLinkingAction);
                    formData.append('deep_link[fullscreen]', this.deepLinkingFullScreen);

                    if(this.deepLinkingActionNews != ''){
                        formData.append('deep_link[article_id]', this.deepLinkingActionNews);
                    }

                    if(this.deepLinkingActionVideos != ''){
                        formData.append('deep_link[video_id]', this.deepLinkingActionVideos);
                    }

                    if(this.deepLinkingActionMDC != ''){
                        formData.append('deep_link[game_id]', this.deepLinkingActionMDC);
                    }

                    Notification.create(formData)
                        .then((response) => {
                            const res = response.data.success ? response.data.success : false;
                            const error = response.data.error ? response.data.error : '';
                            if (res) {
                                Swal.fire("OK!", "Bulk Push Notification Created !", "success")
                                this.refreshNotificatinData();
                                this.closeModal();
                            } else {
                                Swal.fire("Fail!", error, "warning")
                            }
                        })
                        .catch(error => {
                            this.tryingToSubmit = false;
                            const resData = error.response.data.error ? error.response.data.error : 'Failed';
                            const errorMsg = Array.isArray(resData) ? resData[0] : resData;
                            Swal.fire("Fail!", errorMsg, "warning")
                        })
                }
                this.tryingToSubmit = false;
            },

            getNotificationTypes() {
                this.notifTypes =  [];
                Notification.getNotificationTypes()
                .then((response) => {
                    this.notifTypes = response.data.data

                    if(this.notifTypes.length > 0){
                        this.notifData.type = this.notifTypes[0].type_code
                    }
                })
            },

            async getNews() {
                try {
                    await Content.getNews(10)
                        .then((response) => {
                        this.news = response.data.news;
                        if(this.news.length > 0){
                            this.news.forEach((newsItem, index) => {
                                this.deepLinkActionsNews.push({ "articleId": newsItem.source_id, "name": newsItem.title });
                            });
                        }
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Can not get news")
                    }).finally(() => {
                        this.showLoader = false;
                    })

                } catch (error) {
                    this.showLoader = false;
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg("Can not get news")
                }
            },

            async getVideos() {
                try {
                    await Content.getVideos(10)
                        .then((response) => {
                            this.videos = response.data.videos;
                            if(this.videos.length > 0){
                            this.videos.forEach((videoItem, index) => {
                                this.deepLinkActionsVideos.push({ "videoId": videoItem.id, "name": videoItem.title });
                            });
                        }
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Can not get videos")
                    }).finally(() => {
                        this.showLoader = false;
                    })

                } catch (error) {
                    this.showLoader = false;
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg("Can not get videos")
                }
            },

            closeModal() {
                this.showModal = false;
                this.notifData.type = '';
                this.notifData.message = '';
                this.notifData.title = '';
                this.notifData.recipient = 'ALL';
                this.notifData.schedule_time = '';
                this.notifData.language = '';
                this.tryingToSubmit = false;
                this.showSchedule = false;
            },

            scheduleNotification(){
                this.showSchedule = !this.showSchedule

                if(this.schedule_time){
                    this.schedule_time = moment().add(1, 'hours').format("YYYY-MM-DD HH:mm");
                }else{
                    this.schedule_time = '';
                }
            },
            resetProps(){
                this.notifData.type = '';
                this.notifData.message = '';
                this.notifData.title = '';
                this.notifData.recipient = 'ALL';
                this.notifData.schedule_time = '';
                this.notifData.language = '';
                this.tryingToSubmit = false;
                this.showSchedule = false;
                this.deepLinkingAction = '';
                this.deepLinkingActionNews = '';
                this.deepLinkingActionVideos = '';
                this.deepLinkingActionMDC = '';
                this.deepLinkingFullScreen = '';
            },

           onChangeScreen(){
                this.showDeepLinkingActionNews = false;
                this.showDeepLinkingActionVideos = false;
                this.showDeepLinkingActionMDC = false;
                this.deepLinkActionsNews = [];
                this.deepLinkActionsVideos = [];
                this.deepLinkActionsMDC = [];

                if(this.deepLinkingAction == 'mdc'){
                    this.showDeepLinkingActionMDC = true;
                    this.deepLinkActionsMDC.push({ "gameId": "motm", "name": "Man of the match" });
                    this.deepLinkActionsMDC.push({ "gameId": "gts", "name": "Guess the score" });

                }
                else if(this.deepLinkingAction == 'news'){
                    this.showDeepLinkingActionNews = true;
                    this.getNews();
                }
                else if(this.deepLinkingAction == 'videos'){
                    this.showDeepLinkingActionVideos = true;
                    this.getVideos();
                }

            }
        },
    };
</script>

<template>
    <b-modal
        @hidden="resetProps"
        id="add_new_push_notification"
        size="md"
        v-model="showModal"
        title="Bulk Push Notification"
        title-class="text-black font-18"
        body-class="p-3"
    >
    <form @submit.prevent="addNotification">
        <div class="row">
            <div class="col-12">
                <div class="mb-3 text-center">
                    <label class="radio-inline me-4"><input type="radio" id="recipient-all" name="recipient" value="ALL" v-model="notifData.recipient"> All Users</label> 
                    <label class="radio-inline me-4"><input type="radio" id="recipient-ios" name="recipient" value="IOS" v-model="notifData.recipient"> IOS Users</label>
                    <label class="radio-inline me-4"><input type="radio" id="recipient-android" name="recipient" value="ANDROID" v-model="notifData.recipient"> Android Users</label>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">Type</label>
                    <select v-model="notifData.type" class="form-control form-select" type="select" >
                        <option v-for="item in notifTypes" :key="item.type_code" :value="item.type_code">
                            {{item.type_name }} 
                        </option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="control-label form-label">Title</label>
                    <b-form-input v-model="notifData.title" :maxlength="40"></b-form-input>
                </div>

                <b-form-group label="Message" label-for="formrow-message-input" class="mb-3">
                    <b-form-textarea
                            v-model="notifData.message"
                            rows="3"
                            :maxlength="150"
                            :class="{
                                    'is-invalid': submitted && $v.notifData.message.$error,
                                }"
                    >
                    </b-form-textarea>
                    <validationMessages v-if="submitted" :fieldName="'Message'" :validationName="$v.notifData.message"></validationMessages>
                </b-form-group>

                <label class="control-label form-label">Deep Linking</label>


                <div class="mb-3">
                    <label class="control-label form-label">Screen</label>
                    <select v-model="deepLinkingAction" class="form-control form-select" type="select" @change="onChangeScreen">
                        <option></option>
                        <option v-for="screen in deepLinkActions" :key="screen.action" :value="screen.action">
                            {{ screen.name }}
                        </option>
                    </select>
                </div>

              <div class="mb-3" v-show="showDeepLinkingActionNews"  >
                    <label class="control-label form-label">Select News</label>
                    <select v-model="deepLinkingActionNews" class="form-control form-select" type="select" >
                         <option v-for="screenNews in deepLinkActionsNews" :key="screenNews.articleId" :value="screenNews.articleId">
                            {{ screenNews.name }}
                         </option>
                    </select>
                </div>

                <div class="mb-3" v-show="showDeepLinkingActionVideos"  >
                    <label class="control-label form-label">Select Video</label>
                    <select v-model="deepLinkingActionVideos" class="form-control form-select" type="select" >
                        <option v-for="screenVideos in deepLinkActionsVideos" :key="screenVideos.videoId" :value="screenVideos.videoId">
                            {{ screenVideos.name }}
                         </option>
                    </select>
                </div>

                <div class="mb-3" v-show="showDeepLinkingActionMDC"  >
                    <label class="control-label form-label">Select Game</label>
                    <select v-model="deepLinkingActionMDC" class="form-control form-select" type="select" >
                        <option v-for="screenMDC in deepLinkActionsMDC" :key="screenMDC.gameId" :value="screenMDC.gameId">
                            {{ screenMDC.name }}
                         </option>
                    </select>
                </div>

                <!--<div class="mb-5">-->
                    <!--<label class="control-label form-label">Full Screen</label>-->
                    <!--<select v-model="deepLinkingFullScreen" class="form-control form-select" type="select">-->
                        <!--<option></option>-->
                        <!--<option v-for="fs in deepLinkFullScreen" :key="fs.key" :value="fs.key">-->
                            <!--{{ fs.value }}-->
                        <!--</option>-->
                    <!--</select>-->
                <!--</div>-->

                <div class="mb-3" v-if="notification_languages.length > 0">
                    <label class="control-label form-label">Language</label>
                    <select v-model="notifData.language" class="form-control form-select" name="type" type="select" >
                        <option value="">All</option>
                        <option v-for="lang in notification_languages" :key="lang" :value="lang">{{lang}}</option>
                    </select>
                </div>

                <b-form-checkbox-group>
                    <div class="form-check form-switch form-switch-lg mb-3" >
                        <input v-model="showSchedule"
                               type="checkbox"
                               class="form-check-input"
                               id="showSchedule"
                               @click="scheduleNotification()"
                        />
                        <label class="form-check-label" for="showSchedule">Schedule Notification</label>
                    </div>
                </b-form-checkbox-group>

                <b-form-group v-if="showSchedule">
                    <label class="form-label mb-3">Set date/time</label>
                    <flat-pickr
                            v-model="notifData.schedule_time"
                            :config="datetimeconfig"
                            class="form-control"
                    />
                </b-form-group>

            </div>
        </div>
        <input type="hidden" name="csrf_token" v-model="csrf_token">
      </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addNotification" :disabled="tryingToSubmit">
                <b-spinner v-show="tryingToSubmit" small></b-spinner>
                Send</b-button>
        </template>

    </b-modal>
 
</template>